import React, {
  useEffect, useContext, useState, useRef,
} from 'react';
import Breakpoints from 'lib/Breakpoints';
import { VerticalContext } from 'lib/ContextTypes';
import scrollTo from 'lib/scrollToElement';
import { stub as $t } from '@nbcnews/analytics-framework';
import throttle from 'lodash.throttle';
import classNames from 'classnames';

import './styles.themed.scss';

const BUTTON_CLICK_EVENT = 'mbt_backtotop_click';


/**
 * Arrow button that appears when user scrolls up
 * Clicking the arrow will take the user back to the top of the page
 * @returns {React.ReactElement}
 */
const BackToTopButton = () => {
  const vertical = useContext(VerticalContext);

  const scrollRef = useRef(null);
  const threshHoldTimer = useRef(null);

  const [isScrollingDown, setIsScrollingDown] = useState(false);

  // Register tracking event
  useEffect(() => {
    if (typeof $t !== 'undefined') {
      $t('register', BUTTON_CLICK_EVENT, { allowDuplicate: true });
    }
  }, []);

  useEffect(() => {
    scrollRef.current = window.scrollY;

    const handleScroll = throttle(() => {
      const currentScrollTop = window.scrollY;
      // User is scrolling down
      if (currentScrollTop > scrollRef.current) {
        setIsScrollingDown(true);
      }
      // User is scrolling up
      if (currentScrollTop < scrollRef.current) {
        setIsScrollingDown(false);
      }

      scrollRef.current = currentScrollTop;
    });

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(threshHoldTimer.current);
    };
  }, []);

  /** Handle and track button click
   *
   * @param {React.MouseEvent} event click event
   */
  const handleClick = (event) => {
    event.preventDefault();
    // Track click
    if (typeof $t !== 'undefined') {
      $t('track', BUTTON_CLICK_EVENT, {
        breakpoint: Breakpoints.isSorM() ? 'mobile' : 'desktop',
        vertical,
      });
    }
    // Scroll window
    scrollTo(0, 1000, 'easeInOut');
  };

  const isVisible = !isScrollingDown;

  return (
    <button
      className={classNames(
        'back-to-top-button',
        {
          isVisible,
        },
      )}
      data-test="back-to-top-button"
      onClick={handleClick}
      type="button"
    >
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#back_to_top)">
          <rect x="0" y="0" width="44" height="44" className="back-to-top-button__square" />
          <g clipPath="url(#back_to_top_clip)">
            <path
              d="M31 44.417V23.5466"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
              transform="translate(-8.5, -9.5)"
            />
            <path
              d="M20.5642 32.4912L30.9994 22.056L41.4346 32.4912"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="square"
              transform="translate(-8.5, -8.9)"
            />
            <rect x="7.0928" y="7.0928" width="29.8148" height="1.49074" fill="white" />
          </g>
        </g>
        <defs>
          <filter
            id="back_to_top"
            x="0"
            y="0"
            width="44"
            height="44"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="back_to_top_clip">
            <rect
              width="44"
              height="44"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export { BackToTopButton };
