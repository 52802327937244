import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

/**
 * Accordion component.
 *
 * @param {object} props
 * @param {boolean} props.isCollapsible
 * @param {string} props.testId
 * @param {string} props.title
 * @param {React.Element} props.children
 * @returns {React.ReactElement|null} - The rendered component or null.
 */
const Accordion = forwardRef(({
  children,
  isCollapsible,
  isOpen: controlledIsOpen,
  testId,
  title,
  onToggle,
}, ref) => {
  const [localIsOpen, setLocalIsOpen] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const contentRef = useRef(null);

  const isControlled = controlledIsOpen !== undefined;
  const isOpen = isControlled ? controlledIsOpen : localIsOpen;

  useEffect(() => {
    /**
     * On mobile, when the content window is greater than 60% of the viewport, we will show the affordance.
     */
    const checkScrollable = () => {
      if (isCollapsible && isOpen && contentRef.current) {
        const content = contentRef.current;
        // Compare the scrollHeight to the 60vh max in pixels.
        const sixtyVh = window.innerHeight * 0.6;
        setIsScrollable(content.scrollHeight > sixtyVh);
      } else {
        setIsScrollable(false);
      }
    };

    checkScrollable();

    window.addEventListener('resize', checkScrollable);

    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [isCollapsible, isOpen]);

  /**
   * Local toggle function.
   */
  const toggle = () => {
    if (isControlled) {
      onToggle();
    } else {
      setLocalIsOpen((prev) => !prev);
    }
  };

  if (!title || !children) {
    return null;
  }

  return (
    <div
      className={classNames(styles.accordion, { [styles.open]: isOpen }, { [styles.closed]: !isOpen })}
      data-testid={testId}
    >
      <button
        aria-controls="accordion-content"
        aria-expanded={isOpen}
        className={styles.accordion__header}
        data-testid="accordion__button"
        disabled={!isCollapsible}
        onClick={toggle}
        ref={ref}
        type="button"
      >
        {title}
        {isCollapsible && (
          <span
            className={classNames(styles.accordion__icon, 'icon', 'icon-chevron-light-down', { [styles.down]: isOpen })}
            data-testid="accordion__toggle-icon"
          />
        )}
      </button>
      <div
        aria-hidden={!isOpen}
        className={classNames(styles.accordion__content, { [styles.open]: isOpen }, { [styles.closed]: !isOpen })}
        data-testid="accordion__content"
        ref={contentRef}
        role="region"
      >
        {children}
        {/* Affordance showing there are items below the fold */}
        {isScrollable && <div className={styles.affordance} data-testid="accordion__affordance" /> }
      </div>
    </div>
  );
});

Accordion.propTypes = {
  children: PropTypes.string.isRequired,
  isCollapsible: PropTypes.bool,
  isOpen: PropTypes.bool,
  testId: PropTypes.string,
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
};

Accordion.defaultProps = {
  isCollapsible: true,
  isOpen: undefined,
  testId: 'accordion',
  /**
   *
   */
  onToggle: () => {},
};

export { Accordion };
