import React from 'react';
import { SkipLinkOverlay } from 'components/SkipLinkOverlay';

/**
 * Overlays a button that focuses the table of contents when clicked
 * @returns {React.JSX}
 */
export function SkipToTableOfContentsOverlay() {
  /**
   * @returns {void}
   */
  const focusTableOfContents = () => {
    const tableOfContents = document.querySelector('#table-of-contents');
    const firstFocusableChild = tableOfContents?.querySelector('a:not([disabled]), button:not([disabled])');

    firstFocusableChild?.focus();
  };

  return (
    <SkipLinkOverlay
      buttonText="Skip to table of contents"
      onClick={focusTableOfContents}
      showIcon
    />
  );
}
