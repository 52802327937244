import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'components/Accordion';
import { JumpLinkList } from 'components/JumpLinkList';
import classNames from 'classnames';
import styles from './styles.module.scss';

/**
 * Renders a list of jump links to content within the page.
 *
 * @param {object} props
 * @param {boolean} props.isCollapsible
 * @param {Array} [props.links]
 * @param {string} props.id
 * @param {string} props.testId
 * @param {string} props.title
 * @param {number} props.topOffset
 * @param {boolean} props.visible
 * @returns {React.ReactElement|null} - The rendered component or null.
 */
const TableOfContents = ({
  isCollapsible, links, id, testId, title, topOffset, visible,
}) => {
  const ref = useRef(null);
  const componentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(!isCollapsible);

  useEffect(() => {
    ref?.current?.focus();
  }, []);

  /**
   * Keeps the user within the component until they make an active decision.
   *
   * @param {object} e
   */
  const handleKeyDown = (e) => {
    if (e.key === 'Tab' && componentRef.current) {
      const focusableElements = componentRef.current.querySelectorAll(
        'input, button, select, textarea, a[href]',
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey) {
        // If Shift + Tab, move focus backwards
        if (document.activeElement === firstElement) {
          e.preventDefault();
          lastElement.focus();
        }
      } else if (document.activeElement === lastElement) {
        e.preventDefault();
        firstElement.focus();
      }
    }
  };

  /**
   * Toggles the accordion open and close.
   */
  const toggleAccordion = () => {
    if (isCollapsible) {
      setIsOpen((prev) => !prev);
    }
  };

  if (!title || !links.length) {
    return null;
  }

  return (
    <div
      className={classNames(styles.tableOfContents, { [styles.hidden]: !visible })}
      data-testid={testId}
      onKeyDown={isCollapsible ? handleKeyDown : undefined}
      ref={componentRef}
      role="button"
      style={{ top: topOffset }}
      tabIndex="-1"
      id={id}
    >
      <Accordion
        isCollapsible={isCollapsible}
        isOpen={isOpen}
        onToggle={toggleAccordion}
        ref={ref}
        title={title}
      >
        <JumpLinkList
          callback={toggleAccordion}
          clickTrackingId="mbt_tableofcontents_jumplink"
          links={links}
        />
      </Accordion>
    </div>
  );
};

TableOfContents.propTypes = {
  isCollapsible: PropTypes.bool,
  id: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  topOffset: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    linkText: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
  })),
  visible: PropTypes.bool,
};

TableOfContents.defaultProps = {
  isCollapsible: true,
  links: [],
  id: 'table-of-contents',
  testId: 'table-of-contents',
  title: '',
  topOffset: '60px',
  visible: true,
};

export {
  TableOfContents,
};
