import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { stub as $t } from '@nbcnews/analytics-framework';
import styles from './styles.module.scss';

/**
 * Renders a list of links that jump to sections within the page.
 *
 * @param {object} props - Component props.
 * @param {Function} props.callback
 * @param {string} props.clickTrackingId
 * @param {Array} [props.links]
 * @param {string} props.testId
 * @returns {React.ReactElement|null} - The rendered component or null.
 */
const JumpLinkList = ({
  callback,
  clickTrackingId,
  links,
  testId,
}) => {
  useEffect(() => {
    $t('register', clickTrackingId, { allowDuplicate: true });
  }, []);

  if (!links.length) {
    return null;
  }

  /**
   * Handle click tracking and callbacks when the user clicks on a link.
   *
   * @param {object} e - The click event.
   */
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Call the callback
    callback();

    // Analytics tracking in-page click events
    $t('track', clickTrackingId, { action: 'jumpLink', name: e.target.textContent });

    // Proceed with navigation or other actions
    window.location.href = e.target.href;
  };

  return (
    <ul
      className={styles.jumpLinkList}
      data-testid={testId}
    >
      {links.map((link, index) => (
        <li
          className={styles.jumpLinkList__item}
          key={link.href}
        >
          <a
            className={styles.jumpLinkList__itemLink}
            data-testid={`jump-link-list__item-link-${index}`}
            href={link.href}
            onClick={handleOnClick}
          >
            {link.linkText}
          </a>
        </li>
      ))}
    </ul>
  );
};

JumpLinkList.propTypes = {
  callback: PropTypes.func,
  clickTrackingId: PropTypes.string,
  links: PropTypes.arrayOf({
    linkText: PropTypes.string,
    href: PropTypes.string,
  }),
  testId: PropTypes.string,
};

JumpLinkList.defaultProps = {
  /**
   * Callback function.
   */
  callback: () => {},
  clickTrackingId: 'mbt_jump_links',
  links: [],
  testId: 'jump-link-list',
};

export { JumpLinkList };
